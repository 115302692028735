<template>
	<div class="top-main block-row-f">
		<div class="top-main-left pbody-block">
			<div class="block-title-game flex-center">Recommend Games</div>
			<div class="block-grid pc-left-main-list">
				<game-item v-for="item in recommendGames" :game="item" :key="item.handle" :showTitle="false" height="114"></game-item>
			</div>
		</div>
		<div class="top-main-center">
			<div class="pc-center">
				<div class="game-play-window-card">
					<div class="game-play-window-box flex-center" v-if="!played">
						<div class="game-play-window-bg" :style="'background-image: url('+ game.thumb +');'"></div>
						<div class="game-play-window-wrap flex-center">
							<img class="game-play-window-img" :src="game.thumb" :alt="game.title">
							<div class="game-play-window-name">{{game.title}}</div>
							<div class="game-play-window-btn btn-big-color flex-center" @click="played=true">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
									fill="currentColor" width="30" height="30"
									class="play-amt-pc">
									<path fill-rule="evenodd"
										d="M4.5 5.653c0-1.426 1.529-2.33 2.779-1.643l11.54 6.348c1.295.712 1.295 2.573 0 3.285L7.28 19.991c-1.25.687-2.779-.217-2.779-1.643V5.653z"
										clip-rule="evenodd"></path>
								</svg> PLAY GAME
							</div>
						</div>
					</div>
					<div v-else class="game-play-window-box flex-center">
						<!-- <div style="height: 100%; width: 100%; justify-content: center; display: flex;">
							<div style="height: 100%; width: 100%; justify-content: center; display: flex;"> -->
								<iframe :src="game.url" frameborder="0" name="ifr" style="height: 100%; width: auto;"></iframe>
							<!-- </div>
						</div> -->
					</div>
				</div>
				<div class="center-play-info flex-center">
					<img class="center-play-info-img" :src="game.thumb" :alt="game.title">
					<p class="center-play-info-title">{{game.title}}</p>
					<div style="flex: 1 1 0%;"></div>
				</div>
			</div>
		</div>
		<div class="top-main-right">
			<div class="ads-class ad-bg">
				<p style="margin: 0px; color: #ffffff; font-size: 10px; text-align: center;">Advertisement</p>
				<div ></div>
			</div>
			<div class="top-main-right-bottom pbody-block">
				<div class="block-title-game flex-center">New Games</div>
				<div class="block-grid pc-left-main-list">
					<game-item v-for="item in newGames" :game="item" :key="item.handle" :showTitle="false" height="114"></game-item>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import GameItem from '@/components/common/game-item'
	import {
		mapGetters
	} from 'vuex'
	export default {
		props: {
			game: {
				type: Object,
				required: true
			},
			games: {
				type: Array,
				required: true
			}
		},
		components: {
			GameItem
		},
		data() {
			return {
				played: false
			}
		},
		computed: {
			recommendGames() {
				return this.games.filter(v => v.handle !== this.game.handle).slice(0, 8)
			},
			newGames() {
				return this.games.filter(v => v.handle !== this.game.handle).slice(8, 12)
			},
		}
	}
</script>
<style lang="scss" scoped>
	.top-main {
	    margin: 0 auto 10px auto;
	    display: -webkit-box;
	    display: -webkit-flex;
	    display: -moz-box;
	    display: flex;
	}
	
	.top-main-left, .top-main-right {
	    width: 300px;
	}

	.top-main-center {
		flex: 1 1 0%;
	}
	
	.pbody-block {
		padding: 10px 0;
	}

	.ads-class {
		text-align: center;
		margin-bottom: 10px;
		border-radius: 10px;
	    height: 254px;
	    overflow: hidden;
	}

	.pc-center {
		width: 765px;
		margin: 0 auto;
	}

	.center-play-info {
		padding: 10px;
	}
	
	.center-play-info-img {
		width: 50px;
		height:50px;
		border-radius: 10px;
		margin-left: 20px;
	}

	.center-play-info-title {
		font-weight: 700;
		font-size: 18px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		margin: 0 30px;
	}

	.game-play-window-card {
		display: -webkit-box;
		display: -webkit-flex;
		display: -moz-box;
		display: flex;
		width: 100%;
		height: 498px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-moz-box-orient: vertical;
		-moz-box-direction: normal;
		flex-direction: column;
		min-height: 400px;
	}

	.game-play-window-box {
		overflow: hidden;
		position: relative;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-moz-box-flex: 1;
		flex: 1;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-moz-box-pack: center;
		justify-content: center;
		background-color: #000;
		width: 100%;
	}

	.game-play-window-wrap {
		overflow: hidden;
		position: absolute;
		z-index: 1;
		width: 80%;
		text-align: center;
		border-radius: 36px;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-moz-box-pack: center;
		justify-content: center;
	}

	.game-play-window-bg {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		-webkit-filter: blur(30px);
		filter: blur(30px);
		border-radius: 10px;
		z-index: 1;
		background-color: transparent;
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: cover
	}

	.game-play-window-name {
		margin-top: 0;
		color: #fff;
		text-align: center;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		font-size: min(36px, 24.3px);
		font-family: Roboto, sans-serif !important;
		font-weight: 500;
	}

	.game-play-window-img {
	    -o-object-fit: cover;
	    object-fit: cover;
	    -o-object-position: center;
	    object-position: center;
		cursor: pointer;
		width: 220px;
		height: 220px;
		margin: 10px auto;
		border-radius: 50%;
	}

	.game-play-window-btn {
		overflow: hidden;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-moz-box-pack: center;
		justify-content: center;
		width: 280px;
		height: 60px;
		margin: 10px 0;
		font-family: Roboto, sans-serif !important;
		border: 2px solid #fff;
		border-radius: 40px;
		color: #fff;
		font-size: 28px;
		text-align: center;
		cursor: pointer;
		-webkit-transition: all .6s ease;
		transition: all .6s ease;
	}

	.game-play-window-btn:hover .play-amt-pc {
		padding-left: 10px;
		-webkit-animation: playAmt .3s infinite;
		animation: playAmt .3s infinite
	}

	.game-play-window-btn:hover {
		-webkit-transform: scale(1.2);
		transform: scale(1.2);
	}

	.pc-left-main-list {
		display: grid;
		grid-template-columns: repeat(2, 145px);
		grid-auto-rows: auto;
		grid-auto-flow: dense;
		grid-gap: 10px;
	}

	@-webkit-keyframes playAmt {
		0% {
			-webkit-transform: translateX(-18px);
			transform: translateX(-18px)
		}

		to {
			-webkit-transform: translateX(-34px);
			transform: translateX(-34px)
		}
	}

	@keyframes playAmt {
		0% {
			-webkit-transform: translateX(-18px);
			transform: translateX(-18px)
		}

		to {
			-webkit-transform: translateX(-34px);
			transform: translateX(-34px)
		}
	}
</style>
<style lang="scss" scoped>
	.block-title-game  {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-moz-box-pack: center;
		justify-content: center;
		margin: 0 0 12px 5px;
		color: $pgame-block-title-color;
	}
	.center-play-info {
    	background-image: -webkit-gradient(linear, left top, left bottom, from($bg-game-play-info-top), to($bg-game-play-info-bottom));
    	background-image: -webkit-linear-gradient($bg-game-play-info-top, $bg-game-play-info-bottom);
    	background-image: linear-gradient($bg-game-play-info-top, $bg-game-play-info-bottom);
	}
	.center-play-info-title {
		color: $text-game-play-info-color;
	}
	.game-play-window-btn:hover {
		-webkit-transform: scale(1.2);
		transform: scale(1.2);
		background-image: -webkit-gradient(linear, left top, left bottom, from($bg-game-play-btn-hover-top), to($bg-game-play-btn-hover-bottom));
		background-image: -webkit-linear-gradient(top, $bg-game-play-btn-hover-top, $bg-game-play-btn-hover-bottom);
		background-image: linear-gradient(180deg, $bg-game-play-btn-hover-top, $bg-game-play-btn-hover-bottom);
	}
</style>